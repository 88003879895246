.form {
  display: flex;
  flex-direction: column;
}

.link {
  color: #dcb467;
  font-size: 0.75rem;
  /* TODO: Move this to the CSS Variable */
  font-weight: 700;
  text-align: center;
  text-decoration-line: underline;
}

.newUserCallout {
  padding-top: 3.625rem;
  /* TODO: Move this to the CSS Variable */
  color: #bdc1cf;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}
