.eventsSection {
  margin-bottom: 2rem;
  container-type: inline-size;
}

.scheduleHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  @container (min-width: 768px) {
    max-width: 45vw;
  }
}

.eventList {
  padding: 0;
  margin: 0;
  color: var(--color-body);
  font-size: 0.875rem;
  line-height: 1.5;
  list-style-type: none;

  @container (min-width: 768px) {
    max-width: 45vw;
  }

  .eventListItem {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 2rem 0rem;
    border-bottom: 1px solid var(--color-neutral-400);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    gap: 1rem;
    line-height: 1.375rem;

    &:first-child {
      border-top: 1px solid var(--color-neutral-400);
    }
  }

  .eventListRow {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .eventLink {
    color: inherit;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    text-decoration: underline;
  }
}

.noEventText {
  font-size: 0.875rem;
}

.eventListAction,
.eventTimeSection {
  display: flex;
  align-items: center;
}

.actionButtonLink {
  a {
    display: flex;
    width: 100%;
    justify-content: left;
  }
}

.eventTime {
  margin: 4px;
}
