.athletesTableSectionHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  gap: 0.5rem;

  .groupNameLink {
    display: flex;
    align-items: center;
    color: var(--color-neutral-100);
    gap: 0.75rem;

    & > * {
      transition: all 0.3s ease-in-out;
    }

    &:hover,
    &:focus {
      color: var(--color-primary-base);
    }

    &:active {
      color: var(--color-primary-dark);
    }
  }

  .athletesTableSectionHeaderMenu {
    width: 100%;

    @media (min-width: 48em) {
      width: auto;
    }
  }
}

.searchIndicatorContainer:not(:empty) {
  margin-top: 1rem;
}

.groupName {
  color: currentColor;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}
