@keyframes entryAnimation {
  from {
    opacity: 0.2;
    transform: scale(0.975);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.cardLayout {
  display: flex;
  height: 8rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 1rem;
  gap: 0.5rem;
  text-align: center;
}

.card {
  overflow: hidden;
  min-width: 10rem;
  flex: 1;

  border-radius: 0.5rem;
  animation: entryAnimation 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95) backwards;
  background: var(--color-neutral-600);

  &:nth-child(2) {
    animation-delay: 0.05s;
  }

  &:nth-child(3) {
    animation-delay: 0.1s;
  }

  &:nth-child(4) {
    animation-delay: 0.15s;
  }

  &:nth-child(5) {
    animation-delay: 0.2s;
  }

  &:nth-child(6) {
    animation-delay: 0.25s;
  }

  &:nth-child(7) {
    animation-delay: 0.3s;
  }
}

.value {
  position: relative;
  width: min-content;
  margin-bottom: 0.5rem;
  font-size: 3rem;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background: var(--color-primary-light);
    content: '';
  }
}

.label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: var(--color-neutral-200);
  font-size: 0.75rem;
  gap: 0.5rem;

  & svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.statCardsGroup {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
  gap: 1rem;

  &[data-full-width='true'] {
    padding-top: 1.75rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;

    @media (min-width: 48em) {
      padding-top: 2.25rem;
      padding-left: 0;
    }

    @media (min-width: 80em) {
      padding-top: 1.75rem;
    }
  }

  .statCardsGroupError {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
  }
}
