.iconContainer {
  &[data-has-overlay='true'] {
    &:before {
      position: absolute;
      z-index: 1;
      content: '';
      inset: 0;
    }
  }

  &:hover {
    .trashLid {
      transform: rotate(-20deg);
    }
  }

  .trash {
    overflow: visible;

    .trashLid {
      transform-origin: -100% 0%;
      transition: transform 0.3s ease-in-out;
    }
  }
}
