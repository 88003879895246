.search {
  position: relative;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1rem;
  border: 1px solid var(--color-neutral-500);
  border-radius: 0.375rem;
  background: var(--color-neutral-700);
  color: var(--color-neutral-300);
  font-size: 0.875rem;
  gap: 0.75rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: var(--color-neutral-400);
  }

  &:focus-within {
    border-color: var(--color-neutral-300);
    color: var(--color-neutral-200);
  }

  .searchInput {
    width: 100%;
    order: 2;
    border: none;
    background: transparent;
    color: currentColor;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    transition: all 0.3s ease-in-out;

    &::placeholder {
      color: var(--color-neutral-300);
      font-size: 0.875rem;
      opacity: 1;
    }
  }

  .searchButton {
    padding: 0;
    border: none;
    background: none;
    color: currentColor;
    cursor: pointer;
  }

  & svg {
    color: currentColor;
  }

  &:focus-within {
    color: var(--color-body);
  }
}

.search,
.searchInput,
.searchButton {
  transition: color 0.2s ease-in-out;
}
