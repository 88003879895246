.iconButton {
  svg {
    transition: all 0.3s ease-in-out;
  }

  &:active {
    svg {
      transform: scale(0.8);
    }
  }
}
