@keyframes entryAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.panelLayout {
  --header-height: 95.75px;

  isolation: isolate;
  transition: all 0.1s ease-in-out;

  @media (min-width: 48em) {
    display: grid;
    align-items: start;
    padding-right: 1.5rem;
    /* animation: panelLayoutEntry 2s linear; */
    gap: 1.5rem;
    grid-template-columns: auto 1fr;
  }

  & .panel {
    width: 100%;
    padding-bottom: 8rem;
    background: var(--content-layout-background-color);

    @media (min-width: 48em) {
      padding-bottom: 0;
      grid-column: 2;
    }

    & > main {
      padding-bottom: 4.5rem;

      /* & > :where(*:not([data-full-width='true'])) {
        padding: 0 1.5rem;
      } */

      @media (min-width: 48em) {
        padding-bottom: 0;

        /* & > :where(*) {
          &:not([data-full-width='true']) {
            padding: 0 1.5rem 0 0;
          }
        } */
      }
    }
  }

  &[sidebar-state='collapsed'] {
    .sidebar {
      .expandableText {
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }

      @media (min-width: 48em) {
        width: 6rem;
      }

      & .nav {
        & a {
          @media (min-width: 48em) {
          }
        }
      }
    }
  }

  &:before {
    position: fixed;
    z-index: var(--z-index-header);
    left: 0;
    display: block;
    width: 100%;
    height: 13.5rem;
    background: linear-gradient(0deg, transparent, #000);
    content: '';
    opacity: 0;
    pointer-events: none;
    transform: translateY(-16.25rem);
    transition: all 0.5s ease 0s;

    @media (min-width: 80em) {
      height: 9.5rem;
    }
  }
}

@keyframes topBarHeaderEntry {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.sidebarCollapseButton {
  display: none;
  /* transition: margin 0.1s ease-in-out; */

  @media (min-width: 48em) {
    position: relative;
    display: flex;
    width: 1.75rem;
    height: 1.75rem;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    /* animation: collapseButtonEntry 2s ease-in; */
    background: var(--sidebar-background-color);
    color: var(--color-neutral-0);
    grid-column: 1;
    grid-row: 1;
    transition: background 0.2s ease-in-out;

    & svg {
      transform: rotate(180deg);
    }

    &:hover {
      background: var(--color-neutral-600);
      cursor: pointer;
    }
  }

  &[aria-expanded='true'] {
    & svg {
      transform: rotate(0deg);
    }
  }
}

.topBarHeader {
  position: relative;
  top: 0;
  display: grid;
  overflow: hidden;
  /* This is to avoid layout shift on the loading state. Improve this in the future */
  min-height: var(--header-height);
  align-items: center;
  padding-top: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  background: inherit;
  column-gap: 0.75rem;
  isolation: isolate;
  row-gap: 1.5rem;

  @media (min-width: 48em) {
    width: calc(100% + 3rem);
    align-items: center;
    padding-bottom: 1rem;
    margin-left: -1.5rem;
    grid-template-columns: min-content 1fr auto;
    /* animation: topBarHeaderEntry 1s ease-in-out; */
  }

  @media (min-width: 80em) {
    position: sticky;
    z-index: var(--z-index-header);
  }
}

.hasScroll {
  @media (min-width: 80em) {
    &:before {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.title {
  display: none;
  font-size: 1.5rem;
  font-weight: 400;

  &h1 {
    font-weight: 500;
  }

  @media (min-width: 48rem) {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.organizationSection {
  display: flex;
  max-width: 25rem;
  align-items: center;
  gap: 0.5rem;
  grid-column: 2;
  grid-row: 1;
  justify-self: end;

  @media (min-width: 80em) {
    padding: 0 1.5rem;
  }
}

.userMenuContainer {
  display: flex;
  width: max-content;
  align-items: center;
  gap: 0.5rem;
  grid-column: 1;

  .athleteInfo {
    display: flex;
    flex-direction: column;

    .athleteName {
      font-size: 0.875rem;
    }

    .athleteId {
      color: var(--color-neutral-200);
      font-size: 0.75rem;
    }
  }
}

.panelLayoutUserSection {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / -1;
  grid-row: 1;

  @media (min-width: 48em) {
    grid-column: 3;
    grid-row: 1;
  }

  @media (min-width: 80em) {
    margin-bottom: 0;
    margin-left: auto;
  }
}

.panelLayoutUserSectionLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  gap: 0.5rem;
}

.panelLayoutUserSectionError {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  gap: 0.5rem;
}

/* Sidebar */

.logOut {
  position: relative;
  display: flex;
  width: 100%;
  flex: 0;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: white;
  gap: 0.5rem;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  @media (min-width: 48em) {
    transform: translateX(0.5rem);

    &:hover {
      transform: translateX(0.5rem) scale(1.05);
    }
  }

  & > span {
    display: none;

    @media (min-width: 48em) {
      display: block;
    }
  }

  & > svg,
  path {
    fill: currentColor;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    display: none;
  }
}

@keyframes sidebarEntry {
  0% {
    position: fixed;
    z-index: 100000;
    display: flex;
    width: 100dvw;
    height: 100dvh;
    align-items: center;
    justify-content: center;
    background: transparent;
    inset: 0;
    transform: translateY(42vh);
  }

  100% {
    position: fixed;
    z-index: 100000;
    display: flex;
    width: 6rem;
    align-items: center;
    justify-content: center;
    background: transparent;
    inset: 0;
    transform: translateY(0);
  }
}

@keyframes logoEntry {
  0% {
    position: fixed;
    z-index: 100000;
    display: flex;
    width: 100dvw;
    height: 100dvh;
    align-items: center;
    justify-content: center;
    inset: 0;
  }

  50% {
    position: fixed;
    z-index: 100000;
    display: flex;
    width: 64px;
    height: 40px;
    align-items: center;
    justify-content: center;
    inset: 1rem;
  }

  100% {
    position: relative;
    z-index: 100000;
    display: flex;
    width: 64px;
    height: 40px;
    align-items: center;
    justify-content: center;
    background: transparent;
    inset: 0;
  }
}

@keyframes logoImageEntry {
  0% {
    width: 256px;
    height: 160px;
  }

  100% {
    width: 64px;
    height: 40px;
  }
}

@keyframes navEntry {
  0% {
    width: 64px;
    transform: translateY(105vh) scale(2);
  }
  50% {
    width: 64px;
    transform: translateY(0) scale(1);
  }

  100% {
    width: 100%;
    transform: translateY(0) scale(1);
  }
}

@keyframes sidebarOverlay {
  0% {
    background: var(--content-layout-background-color);
  }

  50% {
    background: #3035448b;
  }

  75% {
    background: #303544bc;
  }
  95% {
    background: #303544d7;
  }
  100% {
    background: var(--sidebar-background-color);
  }
}

@keyframes collapseButtonEntry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes navLinkTitleEntry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sidebar {
  position: fixed;
  z-index: var(--z-index-sidebar);
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: center;
  padding: 1rem;
  background: var(--sidebar-background-color);
  transition: width 0.5s ease-in-out;

  .expandableText {
    position: absolute;
    left: 3.5rem;
    transition: all 0.3s ease-in-out 0.5s;
  }

  @media (min-width: 48em) {
    position: sticky;
    top: 0;
    display: flex;
    width: 16rem;
    height: 100vh;
    flex-direction: column;
    padding: 2rem 1rem 1rem;
    /* animation: sidebarEntry 0.5s ease-in-out; */

    &:before {
      position: fixed;
      top: -100vh;
      left: 0;
      display: block;
      width: 100%;
      height: 200vh;
      /* animation: sidebarOverlay 0.5s ease-in-out; */
      content: '';
      pointer-events: none;
    }
  }

  .logoContainer {
    .logo {
      position: relative;
      display: none;
      transition:
        opacity 0.3s ease-in-out,
        transform 0.3s ease-in-out;

      &:hover,
      &:focus {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.8;
        transform: scale(0.9);
      }

      @media (min-width: 48em) {
        display: flex;
        padding-bottom: 3rem;

        .logoImage {
          width: 64px;
          height: 40px;
        }
      }
    }
  }

  .nav {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;
    gap: 0.5rem;

    .dot {
      position: absolute;
      top: -0.5rem;
      right: -0.75rem;
    }

    @media (min-width: 48rem) {
      flex-direction: column;
      padding-right: 0;
      /* animation: navEntry 1s ease-in-out; */
    }

    & ul {
      display: flex;
      width: 100%;
      padding: 0;
      margin: 0;
      gap: 1rem;
      list-style: none;

      & li {
        width: 100%;
      }

      @media (min-width: 48em) {
        flex-direction: column;
      }

      & a {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 0.75rem;
        border-radius: 0.25rem;
        color: var(--color-neutral-100);
        font-size: 0.875rem;
        /* TODO: Move this to the CSS Variable */
        font-weight: 600;
        gap: 0.5rem;
        text-decoration: none;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

        @media (min-width: 48em) {
          justify-content: flex-start;
        }

        & [data-nav-link-label='true'] {
          display: none;

          @media (min-width: 48em) {
            display: block;
          }
        }

        & svg {
          min-width: 24px;
          transition: all 0.3s ease-in-out;

          @media (min-width: 48em) {
            transform: translateX(0.5rem);
          }
        }

        &:hover {
          --color-primary-base-hover: #849b854d;

          background: var(--color-primary-base-hover);
          color: var(--color-neutral-0);

          .dot {
            border-color: var(--color-primary-base-hover);
          }
        }

        &:active {
          --color-primary-base-active: #849b858f;

          background: var(--color-primary-base-active);
          color: var(--color-neutral-0);

          & svg {
            transform: translateX(0.5rem) scale(0.9);
          }

          .dot {
            border-color: var(--color-primary-base-active);
          }
        }

        &[aria-current='page'] {
          background: var(--color-primary-base);
          color: var(--color-neutral-0);

          .dot {
            border-color: var(--color-primary-base);
          }
        }
      }
    }
  }

  .iconAndDotContainer {
    position: relative;
    display: flex;
  }
}

@keyframes headerEntry {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.header {
  display: flex;
  width: 100%;
  min-height: 3.5rem;
  flex-direction: column;
  margin-bottom: 1rem;
  animation: headerEntry 0.3s ease-in-out;
  row-gap: 1.5rem;

  & > * {
    width: 100%;
  }

  @media (min-width: 48em) {
    margin-bottom: 1.5rem;
  }

  @media (min-width: 62em) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;

    & > nav {
      max-width: 50%;
    }

    & > * {
      width: auto;
    }
  }

  .menu {
    display: grid;
    padding: 0 1.5rem;
    gap: 1rem;
    grid-template-columns: auto 1fr;
    row-gap: 0.75rem;

    &:empty {
      display: none;
    }

    @media (min-width: 48em) {
      padding: 0;
    }

    @media (min-width: 62em) {
      justify-content: center;
      grid-auto-flow: column;
      /* grid-template-columns: auto repeat(auto-fill, minmax(0, 1fr)); */
    }

    .headerCTA {
      grid-column: 1 / -1;

      @media (min-width: 62em) {
        margin-left: 0.5rem;
        grid-column: 3;
        grid-row: unset;
      }
    }
  }
}

.searchResultsIndicator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 1rem;
  font-size: 1rem;
  font-weight: 500;
  gap: 1rem;

  .count {
    color: var(--color-primary-light);
    font-weight: 700;
  }

  & a {
    display: inherit;
  }
}

.filterBadge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.5rem;
  border-radius: 4px;
  background: var(--color-neutral-500);
  font-size: 0.75rem;
  gap: 0.75rem;
}
