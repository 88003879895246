.privatePublicToggle {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  justify-self: end;
  transition: all 0.3s ease-in-out;

  &[data-is-disabled='true'] {
    filter: grayscale(100%);
  }

  .option {
    position: relative;
    width: 100%;
    text-align: center;

    & label {
      display: flex;
      align-items: center;
      padding: 0.75rem 1rem;
      border: 1px solid var(--color-secondary-base);
      color: var(--color-secondary-base);
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &[data-checked='false'] {
        &:hover {
          background: rgba(255, 255, 255, 0.05);
        }
      }

      &[data-checked='true'] {
        background: var(--color-secondary-base);
        color: var(--color-neutral-800);
      }

      & span {
        font-family: var(--font-primary);
        font-size: 0.875rem;
      }
    }

    &:first-of-type {
      & label {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
      }
    }

    &:last-of-type {
      & label {
        border-bottom-right-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }
    }
  }
}
