.tableContainer {
  position: relative;
  overflow: auto;
  border: 1px solid var(--color-neutral-500);
  border-radius: 0.3125rem;
  background-color: var(--color-neutral-800);

  .table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    & th,
    & td {
      padding: 1.25rem 1rem;
      text-align: left;
    }

    & th {
      z-index: 9;
      border-bottom: 1px solid var(--color-neutral-500);
      background-color: var(--color-neutral-600);
      color: var(--color-neutral-100);
      font-family: var(--font-primary);
      font-size: 0.875rem;
      font-weight: 600;
      transition:
        background-color 0.2s ease,
        color 0.2s ease;
    }

    & td {
      border-bottom: 1px solid var(--color-neutral-500);
      color: var(--color-neutral-200);
      font-family: var(--font-secondary);
      font-size: 0.875rem;
      font-weight: 500;
    }

    & tr {
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: var(--color-neutral-700); /* Highlight row */
        cursor: pointer; /* Ensure pointer on hover */
      }

      &:active {
        background-color: var(--color-neutral-600);
      }
    }
  }
}

.tableHead {
  width: var(--head-width, auto);
  padding: 0; /* Remove padding from the <th> */
  background-color: var(--color-neutral-600);
  color: var(--color-neutral-100);
  font-family: var(--font-primary);
  font-weight: 600;
  text-align: left;
  transition:
    background-color 0.2s ease,
    color 0.2s ease;

  &.sortable {
    cursor: pointer;
  }
}

.headTitle {
  white-space: nowrap; /* Ensure the title and icon stay on the same line */
}

.tableRow {
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: var(--color-neutral-700); /* Highlight row */
    cursor: pointer; /* Ensure pointer on hover */
  }

  &:active {
    background-color: var(--color-neutral-600);
  }

  & td {
    padding: 1rem;
  }
}

.fullClickableArea {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1rem; /* Add padding inside the clickable area */
  color: inherit;
  text-decoration: none;
}

.headContent {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.sortLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  color: var(--color-neutral-300);
  transition:
    transform 0.2s ease,
    color 0.2s ease;

  &:hover {
    color: var(--color-primary-base);
  }

  svg {
    transition: transform 0.2s ease-in-out;
  }
}

.tableFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0;
  gap: 1.5rem;

  @media (min-width: 48rem) {
    flex-direction: row;
  }

  .pagination {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .pageButton {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    background-color: var(--color-neutral-700);
    color: var(--color-neutral-200);
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: var(--color-primary-base);
      color: var(--color-neutral-100);
    }

    &[disabled] {
      background-color: var(--color-neutral-600);
      color: var(--color-neutral-300);
      cursor: not-allowed;
    }
  }
}
