.pageInfo {
  position: relative;
  display: flex;
  overflow: visible;
  flex-direction: column;
  row-gap: 0.5rem;

  & * {
    color: inherit;
    transition: all 0.1s ease-in-out;
  }

  &:not([data-hover-disabled='true']):hover {
    color: var(--color-primary-base);

    .title,
    .description {
      color: var(--color-primary-base);
    }

    :global(.pencil) {
      animation: write 1s linear;
    }
  }

  &:active {
    color: var(--color-primary-dark);
  }
}

.titleSection {
  display: inline-flex;
  overflow: visible;
  align-items: center;
  gap: 1rem;

  .editIconLink {
    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      inset: 0;
    }
  }
}

.titleInput {
  border: none;
  background: transparent;

  &[placeholder]:empty::before {
    color: var(--color-neutral-300);
    content: attr(placeholder);
  }

  [placeholder]:empty:focus::before {
    content: '';
  }
}

.title {
  margin: 0;
  color: var(--color-neutral-0);
  font-family: var(--font-primary);
  font-size: 1.25rem;
  font-weight: 600;
}

.description {
  /* clamp to 3 lines max */
  display: -webkit-box;
  overflow: hidden;
  /* max-width: 37.5rem; */
  margin: 0;
  -webkit-box-orient: vertical;
  color: var(--color-neutral-200);
  font-family: var(--font-secondary);
  font-size: 0.875rem;
  font-weight: 500;
  -webkit-line-clamp: 3;
}
