.form {
  overflow: visible;
  max-width: 500px;
  container-type: inline-size;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  gap: 1rem;

  @container (max-width: 400px) {
    & > button {
      width: 100%;
    }
  }
}

.obligatoryMessage {
  margin-bottom: 2rem;
  text-align: left !important;
}

.descriptionTextArea {
  height: 7.5rem;
}
