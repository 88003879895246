.container {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--content-layout-background-color);
  border-radius: 50%;
  font-family: var(--font-secondary);
  font-size: 0.75rem;
  opacity: 1;
  transform: scale(1);
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;

  &[data-is-visible='false'] {
    opacity: 0;
    pointer-events: none;
    transform: scale(0);
  }
}

.small {
  font-size: 0.525rem;
}

.primary {
  background-color: var(--color-success);
  color: var(--color-neutral-0);
}

.secondary {
  background-color: var(--color-secondary-base);
  color: var(--sidebar-background-color);
}
