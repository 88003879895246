@keyframes headerEntry {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header {
  display: grid;
  overflow: visible;
  width: 100%;
  padding: 1.5rem;
  animation: headerEntry 1s ease-in-out;
  column-gap: 1rem;
  row-gap: 1.5rem;

  @media (min-width: 23em) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 48em) {
    padding: 0;
    padding-bottom: 1.5rem;
    grid-template-columns: 1fr repeat(auto-fill, auto);
  }

  & ~ .workoutWeek {
    margin-top: 1.5rem;
  }
}

.pageInfoContainer {
  @media (min-width: 48em) {
    grid-row: span 2;
  }

  @media (min-width: 62em) {
    grid-row: 1;
  }
}

.headerCTAs {
  display: grid;
  height: 100%;
  gap: 1rem;
  grid-auto-rows: max-content;

  @media (min-width: 23em) {
    grid-column: 1 / -1;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 48em) {
    margin-left: 1rem;
    grid-column: 2;
    grid-row: 2;
    justify-self: end;
  }

  @media (min-width: 62em) {
    grid-column: 3;
    grid-row: 1;
  }

  &.singleChild {
    grid-template-columns: 1fr;
  }

  & > * {
    width: 100%;
  }
}

.privatePublicToggle {
  width: 100%;
  height: min-content;

  @media (min-width: 23em) {
    width: min-content;
  }

  @media (min-width: 48em) {
    grid-column: 2;
    grid-row: 1;
  }

  @media (min-width: 62em) {
    grid-column: 2;
  }
}

.emptyDayOptions {
  position: absolute;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: var(--content-layout-background-color);
  gap: 0.75rem;
  inset: 0;

  .addWorkoutButton {
    position: relative;
    bottom: 50%;
    width: 70%;
    margin: auto;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .restDayIndicator {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-neutral-200);
    font-size: 1rem;
    gap: 1rem;
    transition: opacity 0.2s ease-in-out;
  }
}

.weeksAlertTable {
  width: 98%;
  margin-top: 1.5rem;
}
