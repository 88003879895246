.chip {
  display: inline-flex;

  & input {
    display: none;

    &:checked + label {
      background: var(--color-secondary-base);
      color: var(--color-neutral-700);
    }
  }

  & label {
    padding: 0.375rem 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
    font-size: 0.75rem;
    outline: 1px solid #6a6f83;
    transition:
      background-color 0.3s ease-in-out,
      color 0.3s ease-in-out;

    &:hover {
      background: var(--color-secondary-base);
      color: var(--color-neutral-700);
    }
  }

  &.static {
    padding: 0.25rem;
    border-radius: 0.25rem;
    background-color: var(--color-neutral-800);
    color: var(--color-secondary-base);
    font-size: 0.6rem;
    font-weight: 600;
    outline: 1px solid var(--color-secondary-base);
    transition:
      background-color 0.3s ease-in-out,
      color 0.3s ease-in-out;
  }
}

.chipGroup,
.chipGroupStatic {
  display: flex;
  flex-wrap: wrap;
}

.chipGroup {
  gap: 0.75rem;
}

.chipGroupStatic {
  margin-top: 0.5rem;
  gap: 0.5rem;
}
