.iconContainer {
  & path {
    transition: fill 0.3s ease-in-out;
  }

  &:hover {
    & path {
      fill: currentColor;
    }
  }
}
