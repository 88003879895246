.alertContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  margin: auto;
  font-size: 1.5rem;
  gap: 0.5rem;
  text-align: center;

  .alertDescription {
    max-width: 15rem;
  }
}

.alertTableContainer {
  border-radius: 0.3125rem;
  background: var(--color-neutral-700);

  .alertDescription {
    max-width: 17rem;
  }
}

.alertButton {
  &:after {
    position: absolute;
    content: '';
    inset: 0;
  }
}

.icon {
  color: var(--color-neutral-300);
}

.emptyStateCTA {
  margin-top: 0.75rem;
}

.alertTitle {
  color: var(--color-neutral-0);
  font-family: var(--font-primary);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

.alertDescription {
  color: var(--color-neutral-200);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}
