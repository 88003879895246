.chatMessages {
  display: grid;
  height: max-content;
  max-height: calc(61.5dvh - var(--header-height));
  padding: 1.5rem;
  margin: 0;
  gap: 1.75rem;
  list-style-type: none;
  overflow-anchor: auto;
  overflow-y: auto;
  overscroll-behavior: contain;

  @media (min-width: 91em) {
    max-height: calc(65dvh - var(--header-height));
  }

  @media (min-width: 121em) {
    max-height: calc(71.5dvh - var(--header-height));
  }

  &[data-is-loading='true'] {
    height: unset;
    max-height: calc(69dvh - var(--header-height));
  }

  & li[data-sender] {
    display: flex;
    height: max-content;
    flex-direction: column;
    padding: 0;
    margin: 0;
    gap: 0.37rem;
    justify-self: flex-start;

    &[data-sender='me'] {
      justify-self: end;

      .message {
        border: 1px solid var(--color-neutral-500);
      }

      .messageTimeIndicator {
        text-align: right;
      }
    }

    &[data-sender='other'] {
      .message {
        background: var(--color-neutral-500);
      }
    }
  }

  .messageWithAvatar {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .message {
    width: 100%;
    padding: 0.5rem 1rem;
    border-radius: 1rem;

    &:hover {
      .copyIcon {
        opacity: 1;
      }
    }

    .textAndOptions {
      display: flex;
      gap: 1.45rem;
    }

    .messageText {
      margin: 0.75rem 0;
      color: var(--color-neutral-0);
      font-family: var(--font-secondary);
      font-size: 0.875rem;
      line-height: 1rem;

      & > p {
        margin: 0;
      }
    }

    .senderName {
      color: var(--color-primary-light);
      font-family: var(--font-secondary);
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1rem;
    }

    .messageFilesContainer {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .sameTypeFilesContainer {
      display: flex;
      max-width: 50vw;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 1rem;

      & a {
        color: var(--color-neutral-200);
        font-family: var(--font-secondary);
      }
    }

    .messageMediaFile {
      position: relative;
      z-index: 1;
      display: flex;
      max-width: 30vw;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem;
      cursor: pointer;

      &:after {
        position: absolute;
        z-index: 2;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        content: '';
        inset: 0;
        opacity: 0;
        pointer-events: none;
        transition: all 0.2s ease-in-out;
      }

      & img,
      video {
        width: 100%;
        height: auto;
        border-radius: 0.25rem;
      }

      & svg {
        position: absolute;
        z-index: 3;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }

      &:hover {
        & svg {
          opacity: 1;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    .messageDocFile {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      color: var(--color-neutral-0);
      cursor: pointer;
      font-family: var(--font-secondary);
      font-size: 0.875rem;
      gap: 0.5rem;

      & span {
        transition: all 0.2s ease-in-out;
      }

      & strong {
        font-weight: 500;
      }
    }
  }

  .messageTimeIndicator {
    color: var(--color-neutral-200);
    font-size: 0.625rem;
    line-height: 1rem;

    &[data-is-group-message='true'] {
      margin-left: 2rem;
    }
  }
}

.emptyState,
.errorState {
  margin: auto;
}

.copyIcon {
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.messageTextAreaContainer {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;

  &.compact {
    color: var(--color-neutral-0);
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;

    .fileInputLabelText {
      display: none;
    }
  }
}

.messageTextArea {
  display: flex;
  flex: 1;
  flex-direction: column;
  border: none !important;
  border-top: 0.0625rem solid var(--color-neutral-500) !important;

  .messageTextAreaMenuBar {
    border-color: var(--color-neutral-500);
  }

  .messageTextAreaContent {
    /* height: 50% !important; */
    padding: 0;

    :global(.ProseMirror) {
      width: 100%;
      min-height: 100%;
      padding: 0.5rem;
      overflow-y: auto;

      & p {
        margin: 0;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.messageFilesArea {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.25rem 0.5rem 0;
  color: var(--color-neutral-0);
  column-gap: 0.5rem;
  font-family: var(--font-secondary);
  font-size: 0.875rem;
  line-height: 1.25rem;

  & strong {
    font-weight: 500;
  }

  .messageFile {
    display: flex;
    align-items: center;
    gap: 0.38rem;

    & svg {
      cursor: pointer;
    }
  }
}

.messageActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  margin-top: auto;
  & svg {
    color: var(--color-neutral-300);
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: var(--color-neutral-0);
    }
  }
}

@keyframes chatMessageListItemEntry {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.chatMessageListItem {
  animation: chatMessageListItemEntry 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  & p {
    margin: 0;
  }
}

.fileInputLabel {
  display: flex;
  padding: 0.5rem;
  cursor: pointer;
  gap: 0.5rem;
}

.fileInputLabelText {
  font-size: 0.875rem;
  transition: color 0.2s ease-in-out;
}

.characterCount {
  position: relative;
  bottom: 0.25rem;
  color: var(--color-neutral-200);
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: right;
}

.charCountAndSend {
  display: flex;
  align-items: flex-end;
  gap: 0.85rem;
}
