.container {
  color: var(--color-neutral-200);
  font-family: var(--font-secondary);
  font-size: 1rem;
  font-weight: 500;

  & strong {
    color: var(--color-primary-light);
  }
}
