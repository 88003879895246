.iconContainer {
  &[data-has-overlay='true']:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    inset: 0;
  }

  &:hover {
    .frontLayer {
      transform: translate(-5%, -5%);
    }

    .backLayer {
      transform: translate(5%, 5%);
    }
  }
}

.icon {
  overflow: visible;

  .frontLayer,
  .backLayer {
    transition: all 0.3s ease-in-out;
  }
}
