.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  & a {
    text-decoration: none;
  }

  & a[aria-disabled='true'] {
    border: 1px solid currentColor;
    background: none;
    color: var(--color-neutral-400);
    pointer-events: none;

    &:active {
      border: 1px solid currentColor;
      background: none;
      color: var(--color-neutral-400);
    }
  }

  .defaultButton {
    position: relative;
    display: none;
    height: 2.625rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border: 1px solid currentColor;
    border-radius: 0.25rem;
    background: none;
    color: var(--color-primary-base);
    column-gap: 0.25rem;
    font-family: var(--font-primary);
    font-size: 0.875rem;

    &:active {
      border: 1px solid currentColor;
      color: var(--color-primary-dark);
    }
  }

  @media (min-width: 48rem) {
    flex-direction: row;
    gap: 1rem;

    .defaultButton {
      display: flex;
    }
  }

  .pageButtonsRangeContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 48rem) {
      align-items: center;
      gap: 1rem;
    }
  }

  .pageButton {
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    background: none;
    color: var(--color-neutral-0);
    outline: none;

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &:focus {
      /* TODO: Get rid of this once we move to hls() */
      background: var(--color-primary-base);
    }

    &:active {
      background: var(--color-primary-dark);
    }

    &[aria-current='page'] {
      background: var(--color-primary-dark);
    }
  }
}

.dotContainer {
  padding: 0.5rem 1rem;
  background: none;
  font-family: var(--font-primary);
  font-size: 0.75rem;
}
