.addPartButton {
  z-index: 2;
  bottom: -0.625rem;
  /* There's a fixed height in the Figma mockup, this is not good practice */
  height: 1.5rem;
  margin: auto;
  grid-column: 2;
  justify-self: center;
  transition: bottom 0.2s ease-in-out 1s;
}

.partNameFieldWrapper {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.workoutPartLetter {
  color: var(--color-neutral-0);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
}

.workoutPartNameInput {
  width: 100%;
  border: none;
  background: none;
  color: var(--color-neutral-100);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;

  &:placeholder {
    color: var(--color-neutral-300);
    opacity: 1;
  }
}

.workoutPartsListFlipper {
  display: contents;
}

.workoutPartsList {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0;
  padding-top: 0.25rem;
  margin: 0;
  gap: 1rem;
  list-style-type: none;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  overflow-x: hidden;
  overflow-y: visible;
  scrollbar-width: none; /* Firefox */
  will-change: transform;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  &[data-with-divider='true'] {
    border-bottom: 1px solid var(--color-neutral-500);
    margin-bottom: -0.925rem;
  }
}

.workoutPartInputContainer {
  position: relative;
  padding-bottom: 1rem;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: url('/assets/library/workout/workout-part-divider.svg');
    content: '';
    opacity: 0;
    transform: scaleY(0);
    transition:
      transform 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
  }

  &[data-current='false'] {
    &:after {
      opacity: 1;
      transform: scaleY(1);
    }
  }
}

.workoutPartsListItem {
  position: relative;
  will-change: transform;
}

.deletePartButton {
  color: var(--color-neutral-400);

  &:hover,
  &:focus {
    color: var(--color-neutral-200);
  }

  &:active {
    color: var(--color-neutral-0);
  }
}
