.form {
  position: relative;

  &:hover,
  &:focus-within {
    .filterButton {
      background: var(--color-neutral-400);
    }

    .content {
      opacity: 1;
      pointer-events: all;
    }
  }
}

.contentInner {
  position: relative;
  display: flex;
  overflow: visible;
  width: 17.625rem;
  height: auto;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.25rem;
  background: var(--color-neutral-700);
  gap: 1.5rem;

  @media (min-width: 992px) {
    & {
      max-height: max(62vh, 360px);
    }
  }
}

.content {
  position: absolute;
  z-index: 100;
  top: 4rem;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.filterButton {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
  border-radius: 0.375rem;
  background: var(--color-neutral-700);
  color: var(--color-body);
  font-size: 0.875rem;
  outline: 1px solid var(--color-neutral-500);
  transition: all 0.2s ease-in-out;

  &:before {
    position: absolute;
    top: 1rem;
    width: 100%;
    height: 100%;
    content: '';
  }

  &:active {
    background: var(--color-neutral-400);
    color: var(--color-neutral-200);
  }
}
