.overlayContent {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-neutral-200);
  font-size: 1rem;
  gap: 1rem;
}
