.breadcrumb {
  --link-border-thickness: 2px;

  position: relative;
  display: flex;
  overflow: auto;
  height: calc(100% + var(--link-border-thickness));
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;

  @media (min-width: 48em) {
    grid-column: 1 / -1;
    grid-row: 2;
  }

  @media (min-width: 80em) {
    grid-column: 2;
    grid-row: 1;
  }

  & h1 {
    display: block;
    margin: 0;
    color: var(--color-neutral-100);
    contain: layout;
    font-size: 1.5rem;
    font-weight: 500;

    @media (min-width: 48em) {
      overflow: hidden;
      max-width: 25rem;
      text-overflow: ellipsis;
    }
  }

  .link {
    position: relative;
    bottom: var(--link-border-thickness);
    overflow: visible;
    color: var(--color-primary-base);
    contain: layout;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    transition: all 0.2s ease-in-out;

    @media (min-width: 48em) {
      overflow: hidden;
      max-width: 25rem;
      text-overflow: ellipsis;
    }

    & > span {
      contain: layout;
    }

    &:after {
      position: absolute;
      bottom: -0.5rem;
      left: 0;
      width: 100%;
      height: var(--link-border-thickness);
      border-radius: 10px;
      background: rgba(132, 155, 133, 0.25);
      content: '';
      transition: all 0.2s ease-in-out;

      &:last-of-type {
        display: block;
      }

      @media (min-width: 48rem) {
        display: block;
      }
    }

    &:hover {
      &:after {
        background: var(--color-primary-base);
      }
    }

    &:active {
      color: var(--color-primary-dark);

      &:after {
        background: var(--color-primary-dark);
      }
    }
  }

  .separator {
    display: none;
    color: var(--color-neutral-400);
    font-size: 0.75rem;
    pointer-events: none;

    &:last-of-type {
      display: inline-block;
    }

    @media (min-width: 48rem) {
      display: inline-block;
      font-size: 1rem;
    }
  }

  & ol {
    display: contents;
    list-style-type: none;

    & li {
      display: contents;

      &:last-child {
        .separator {
          display: none;
        }

        .link {
          color: var(--color-neutral-100);

          &:after {
            opacity: 0;
          }
        }
      }
    }
  }
}
