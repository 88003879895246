.weeks {
  display: grid;
  padding: 0;
  padding-top: 1.5rem;
  padding-bottom: 7rem;
  margin: 0;
  margin-right: -1.5rem;
  gap: 1.5rem;
  list-style-type: none;

  &[data-is-time-traveling='true'] {
    .day {
      animation-name: none !important;
    }
  }
}

.collapsedWeek {
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  background: var(--color-neutral-500);
  h3 {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 500;
  }
  span {
    margin-left: 0.5rem;
  }
}

.week {
  position: relative;
  display: flex;
  min-height: min(75vh, 50rem);
  flex-wrap: nowrap;
  padding-right: 1.5rem;
  isolation: isolate;
  overflow-x: auto;
  overflow-y: hidden;
  transition: opacity 0.3s ease-in-out;

  &[data-is-dragging='true'] {
    z-index: var(--z-index-dragging);
    cursor: grabbing;
    opacity: 0.5;

    :global(.tippy-box) {
      display: none !important;
    }
  }

  &[data-is-mounted='true'] {
    .day {
      animation-name: none !important;
    }
  }

  & ~ .addWeekButton {
    margin-top: 1.5rem;
  }

  &:only-child {
    min-height: 62vh;
  }

  &::before,
  &::after {
    position: fixed;
    z-index: 1;
    top: var(--position-y);
    bottom: 0;
    width: 2.5rem;
    height: 100%;
    /* Pseudo-elements for left and right gradients */
    content: '';
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
  }

  &::before {
    /* Gradient on the left */
    left: 0;
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
    transform: translateX(-100%);

    @media (min-width: 48em) {
      left: 7.5rem;
    }
  }

  &:after {
    right: 0;
    /* Gradient on the right */
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0)
    );
    /* transform: translateX(100%); */
  }

  &.scrollLeft {
    &:before {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  &.scrollRight {
    &:after {
      opacity: 1;
    }
  }

  &[data-state='editing'] {
    .day {
      height: 100%;
    }
  }

  .days {
    display: contents;
    list-style-type: none;
  }
}

@keyframes dayEntryAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.day {
  position: relative;
  display: flex;
  width: 14.25%;
  min-width: 18rem;
  max-height: 100%;
  flex-direction: column;
  animation-duration: 1s;
  animation-fill-mode: backwards;
  animation-name: dayEntryAnimation;
  animation-timing-function: ease-in-out;
  isolation: isolate;
  transition:
    opacity 0.3s ease-in-out,
    min-width 0.3s ease-in-out;

  @media (min-width: 48em) {
    min-width: 13rem;
  }

  &[data-should-skip-animation='true'] {
    animation-name: none;
  }

  &:not([data-state='drag-overlay']) {
    :global(.tippy-box) {
      display: none !important;
    }

    .dayContent {
      border-top: none;
    }
  }

  &[data-is-dragging='true'] {
    z-index: var(--z-index-dragging);
    animation-name: none;
    cursor: grabbing;
    opacity: 0.5;
  }

  &[data-state='drag-overlay'],
  &[data-state='editing'] {
    .emptyDayOptions {
      opacity: 0;
      pointer-events: none;
    }
  }

  &[data-is-dragging='false'] {
    &[data-state='editing'] {
      min-width: 20rem;
      height: 100%;

      .dayContent {
        background: #303543;

        &:before {
          background: var(--color-neutral-500);
        }
      }
    }
  }

  &:first-child {
    margin-left: 1rem;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.25rem;

    .dayHeader {
      border-top-left-radius: 0.25rem;
    }

    .dayContent {
      border-bottom-left-radius: 0.5rem;
    }

    @media (min-width: 48em) {
      margin-left: 0;
    }
  }

  &:last-child {
    margin-right: 1.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.25rem;

    @media (min-width: 62em) {
      margin-right: 0;
    }

    .dayHeader {
      border-top-right-radius: 0.25rem;
    }
    .dayContent {
      border-bottom-right-radius: 0.5rem;
    }
  }

  &:nth-child(2) {
    animation-delay: 0.1s;
  }

  &:nth-child(3) {
    animation-delay: 0.2s;
  }

  &:nth-child(4) {
    animation-delay: 0.3s;
  }

  &:nth-child(5) {
    animation-delay: 0.4s;
  }

  &:nth-child(6) {
    animation-delay: 0.5s;
  }

  &:nth-child(7) {
    animation-delay: 0.6s;
  }
  /*  */

  .dayHeader {
    display: flex;
    width: 100%;
    height: 2.25rem;
    align-items: center;
    justify-content: flex-end;
    padding: 0.625rem 0.75rem;
    background: var(--color-neutral-500);
    font-size: 0.875rem;

    .titleContainer {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .collapsedButton {
      padding: 0;
      margin-top: 0.25rem;
    }

    .weekOrder {
      left: 0;
      margin: 0;
      color: var(--color-neutral-100);
      font-family: var(--font-primary);
      font-size: 0.875rem;
      font-weight: 600;
    }

    .dayOrder {
      color: var(--color-neutral-100);
      font-family: var(--font-primary);
      font-weight: 600;
    }
  }

  .dayContent {
    position: relative;
    height: 100%;
    border: 0.0625rem solid var(--color-neutral-500);
    background: var(--color-neutral-900);
    overflow-x: hidden;
    overflow-y: auto;

    &:hover {
      .restDayIndicator {
        opacity: 0;
      }

      .emptyDayOptionsCTAs {
        opacity: 1;
      }
    }
  }

  &:first-child {
    .dayHeader {
      justify-content: space-between;
    }
  }
}

.addWeekButton {
  width: max-content;
  justify-content: center;
  margin: auto auto 2rem auto !important;
  justify-self: center;
  margin-inline: auto;
  transition: opacity 0.2s ease-in-out;

  &[aria-hidden='true'] {
    opacity: 0;
    pointer-events: none;
  }
}

.emptyDayOptions {
  position: absolute;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: var(--content-layout-background-color);
  gap: 0.75rem;
  inset: 0;

  &[aria-hidden='true'] {
    opacity: 0;
    pointer-events: none;
  }

  .emptyDayOptionsCTAs {
    position: relative;
    bottom: 50%;
    display: flex;
    width: 70%;
    height: min-content;
    flex-direction: column;
    margin: auto;
    gap: 0.75rem;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    & button {
      transform: none;
      transition:
        opacity 0.2s ease-in-out,
        transform 0.2s ease-in-out;
    }

    &[data-should-show-paste-button='false'] {
      & button:first-child {
        transform: translateY(50%);
      }
    }
  }

  .pasteDayButton {
    transform: scale(1);
    transition:
      opacity 0.2s ease-in-out,
      transform 0.2s ease-in-out;

    &[aria-hidden='true'] {
      opacity: 0;
      pointer-events: none;
      transform: scale(0);
    }
  }

  .restDayIndicator {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--color-neutral-200);
    font-size: 1rem;
    gap: 1rem;
    transition: opacity 0.2s ease-in-out;
  }
}

.menuBar {
  display: flex;
  align-items: center;
  padding: 1rem 0.75rem 0.75rem;
  margin: 0;
  gap: 0.75rem;
  list-style-type: none;

  & button {
    transition: color 0.2s ease-in-out;

    & svg {
      width: 1.25rem;
      height: 1.25rem;
      aspect-ratio: 1;
    }

    &:hover {
      color: var(--color-neutral-400);
    }
  }

  .menuBarCheckbox {
    position: relative;
    /* TODO: Get rid of this as is a clear sign of smelly CSS from the Checkbox component */
    bottom: 0.05rem;
    left: 0.25rem;
  }

  .pushToRight {
    margin-left: auto;
  }

  .copyButtonItem {
    transform: scale(1);
    transition:
      opacity 0.2s ease-in-out,
      transform 0.2s ease-in-out;

    &[aria-hidden='true'] {
      opacity: 0;
      pointer-events: none;
      transform: scale(0);
    }
  }

  .pasteButtonItem {
    margin-left: auto;
    transform: scale(1);
    transition:
      opacity 0.2s ease-in-out,
      transform 0.2s ease-in-out;

    &[aria-hidden='true'] {
      opacity: 0;
      pointer-events: none;
      transform: scale(0);
    }
  }
}

.actionToast {
  /* z-index: calc(var(--z-index-dragging) + 200); */
  display: flex;
  align-items: center;
  /* padding: 0.75rem 1rem; */
  /* border: none;
  border-radius: 0.625rem;
  background: var(--color-neutral-800);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); */
  color: var(--color-neutral-200);
  gap: 1rem;

  .deleteButton {
    position: relative;
    color: var(--color-alert);
  }

  &[data-is-dragging-week='true'] {
    & li {
      position: static;
    }

    .deleteButton {
      position: static !important;
    }
  }

  .actionToastMenu {
    display: flex;
    align-items: center;
    padding: 0 0.5rem 0 1rem;
    border-left: 0.0625rem solid var(--color-neutral-500);
    margin: 0;
    gap: 0.5rem;
    list-style-type: none;
  }

  .actionToastMenuItem {
    position: relative;
  }

  .actionToastMessage {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;
    gap: 0.5rem;
  }
}

.errorContainer {
  display: flex;
  align-items: center;
  margin-right: -0.1rem;
  margin-left: -0.5rem;
  gap: 1rem;
}

.overlay {
  position: fixed;
  z-index: var(--z-index-overlay);
  top: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.6);
  cursor: progress;

  &[data-is-visible='true'] {
    display: block;
  }
}
