.menuBar {
  position: sticky;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  padding: 0.25rem 0.75rem;
  border-bottom: 1px solid var(--color-neutral-400);
  background: var(--menu-color);
  gap: 1rem;

  & button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    margin: 0;
    background: none;
    color: var(--color-neutral-200);
    cursor: pointer;
    font-size: 1rem;
    font-weight: inherit;
    font-weight: 700;
    line-height: 1rem;
    transition: color 0.2s ease-in-out;

    &[data-is-active='true'] {
      color: var(--color-primary-base);
    }
  }

  .headingMenu {
    position: relative;

    .headingMenuItems {
      position: absolute;
      z-index: var(--z-index-dropdown);
      top: 2rem;
      left: 0;
      border-radius: 0.625rem;
      background: var(--color-neutral-800);

      /* make a pointer up */
      &::before {
        position: absolute;
        top: -0.5rem;
        left: 1rem;
        width: 0;
        height: 0;
        border-right: 0.5rem solid transparent;
        border-bottom: 0.5rem solid var(--color-neutral-800);
        border-left: 0.5rem solid transparent;
        content: '';
      }

      & button {
        width: 100%;
        padding: 1rem;
        margin: 0;
        transition: background 0.2s ease-in-out;
        white-space: nowrap;

        &:first-child {
          border-radius: 0.625rem 0.625rem 0 0;
        }

        &:last-child {
          border-radius: 0 0 0.625rem 0.625rem;
        }

        &:hover {
          background: var(--color-neutral-600);
        }

        &[data-is-active='true'] {
          background: var(--color-neutral-700);
        }
      }
    }
  }

  .bold {
    font-family: var(--font-secondary);
    font-weight: 700;
  }

  .strike {
    font-family: var(--font-secondary);
    font-weight: 400;
    text-decoration: line-through;
  }

  .underline {
    font-family: var(--font-secondary);
    font-weight: 400;
    text-decoration: underline;
  }

  .highlight {
    font-family: var(--font-secondary);
    font-size: 0.75rem;
    font-weight: 400;
  }
}

.footer {
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  background: var(--menu-color);
  gap: 1rem;
}

.richTextContent {
  overflow: hidden;
  height: 100%;
  color: var(--color-neutral-0);

  :global(p.is-empty::before) {
    height: 0;
    color: var(--color-neutral-200);
    content: attr(data-placeholder);
    float: left;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3;
    pointer-events: none;
  }

  :global(.ProseMirror) {
    overflow: hidden;
    height: var(--fixed-height, 100%);
    min-height: var(--min-height, auto);
    padding-top: 0.5rem;
    overflow-y: auto;

    &:focus {
      outline: none;
    }
  }
}

.richText {
  height: 100%;

  &[data-variant='outlined'] {
    border: 1px solid var(--color-neutral-400);
    border-radius: 6px;

    .richTextContent {
      padding-inline: 0.5rem;
    }
  }

  & ul,
  ol {
    padding: 0 1rem 0 1.5rem;
  }

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  & code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  & pre {
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;

    & code {
      padding: 0;
      background: none;
      color: inherit;
      font-size: 0.8rem;
    }
  }

  & img {
    max-width: 100%;
    height: auto;
  }

  & blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  & hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  &.readOnly {
    .richTextContent,
    .ProseMirror {
      color: 100%;
      color: var(--color-neutral-400);
    }
  }
}

.submitButtonContainer {
  z-index: 1000;
  margin-left: auto;
  cursor: pointer;

  .sendIcon {
    transition:
      opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }

  &:hover {
    .sendIcon {
      opacity: 0.75;
    }
  }

  &:active {
    .sendIcon {
      transform: scale(0.95);
    }
  }

  & button {
    padding: 0;
  }
}

.highlight {
  background: none;
  color: var(--color-primary-light);
  font-weight: 700;
}

.visualizer {
  & mark {
    background: none;
    color: var(--color-primary-light);
    font-weight: 700;
  }
}
