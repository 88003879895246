.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 2rem;

  @media (min-width: 48em) {
    flex-direction: row;
  }
}

.summaryInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  @media (min-width: 48em) {
    flex-direction: row;
  }
}

.athleteInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;

  @media (min-width: 48em) {
    align-items: flex-start;
  }
}

.weeksAlertTable {
  width: 98%;
  margin-top: 1.5rem;
}
