.container {
  z-index: var(--floating-chat-window-z-index);
  display: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }

  @media (min-width: 48em) {
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    display: flex;
    width: 3rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    background-color: var(--color-secondary-base);
    outline: none;
  }

  & svg {
    color: var(--color-neutral-800);
  }
}

.dot {
  position: absolute;
  top: -0.5rem;
  left: 0;
}
