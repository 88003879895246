.error {
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  animation: fadeIn 0.5s ease-in-out;
  background: var(--color-alert);
  font-size: 0.75rem;
  gap: 0.5rem;
  grid-template-columns: auto 1fr;
}
