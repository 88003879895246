.menuButton {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border: none;
  background: none;
  color: var(--color-neutral-100);
  font-size: 1rem;
  font-weight: 500;

  & svg {
    color: var(--color-primary-base);
    transition: color 0.3s ease-in-out;
  }

  &:hover {
    & svg {
      color: var(--color-primary-light);
    }
  }
}

.menuItems {
  position: fixed;
  z-index: var(--z-index-dropdown);
  top: 3.5rem;
  display: flex;
  width: 100%;
  min-width: var(--width, 8rem);
  flex-direction: column;
  padding: 0;
  border-radius: 0.375rem;
  margin: 0;
  background: var(--color-neutral-600);
  box-shadow: 0px 8px 20px 0px rgba(27, 31, 40, 0.5);
  list-style-type: none;
  opacity: 0;
  overflow-y: auto;
  text-align: left;
  transform-origin: top right;
  transition: opacity 0.3s ease-in-out;

  &[data-is-open='false'] {
    opacity: 0;
    pointer-events: none;
  }

  &[data-is-open='true'] {
    opacity: 1;
    pointer-events: all;
  }

  &.small {
    .menuItem {
      padding: 0.5rem 0.75rem;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.33;
    }
  }

  &.large {
    .menuItem {
      padding: 0.75rem 1rem;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  &[data-justify-content='center'] {
    .menuItem {
      justify-content: center;
    }
  }

  &:focus {
    outline: none;
  }
}

.menuItem {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  color: var(--color-neutral-100);
  cursor: pointer;
  gap: 0.5rem;
  text-align: center;
  transition: all 0.3s ease-in-out;

  & svg {
    width: 1rem;
  }

  & > * {
    display: contents;
  }

  &:focus,
  &[aria-selected='true'] {
    background: var(--color-neutral-800);
    outline: none;
  }
}
/*  */
