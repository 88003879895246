.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  aspect-ratio: 1;
  background-color: var(--color-neutral-500);
  background-position: top center;
  background-size: cover;
  color: var(--color-neutral-100);
  font-family: var(--font-primary);
}

.placeholder {
  padding: 0.25rem;
}

.xl {
  width: 96px;
  height: 96px;
  font-size: 1.625rem;
}

.lg {
  width: 64px;
  height: 64px;
  font-size: 1.625rem;
}

.md {
  width: 48px;
  height: 48px;
  font-size: 1.35rem;
}

.sm {
  width: 40px;
  height: 40px;
  font-size: 1.25rem;
}

.xs {
  width: 32px;
  height: 32px;
  font-size: 1.125rem;
}

.xxs {
  width: 24px;
  height: 24px;
  font-size: 1rem;
}

.minimal {
  width: 16px;
  height: 16px;
  background-color: var(--color-neutral-600);
  font-size: 0.5rem;
}
