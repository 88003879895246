@keyframes entryAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header {
  display: grid;
  padding: 1.5rem;
  gap: 1.5rem;
  grid-template-columns: 1fr 1fr;

  & > * {
    animation: entryAnimation 0.5s ease-in backwards;

    &:nth-child(2) {
      animation-delay: 0.05s;
    }

    &:nth-child(3) {
      animation-delay: 0.1s;
    }

    &:nth-child(4) {
      animation-delay: 0.15s;
    }

    &:nth-child(5) {
      animation-delay: 0.2s;
    }

    &:nth-child(6) {
      animation-delay: 0.25s;
    }

    &:nth-child(7) {
      animation-delay: 0.3s;
    }

    &:nth-child(8) {
      animation-delay: 0.35s;
    }

    &:nth-child(9) {
      animation-delay: 0.4s;
    }

    &:nth-child(10) {
      animation-delay: 0.45s;
    }

    &:nth-child(11) {
      animation-delay: 0.5s;
    }

    &:nth-child(12) {
      animation-delay: 0.55s;
    }

    &:nth-child(13) {
      animation-delay: 0.6s;
    }

    &:nth-child(14) {
      animation-delay: 0.65s;
    }

    &:nth-child(15) {
      animation-delay: 0.7s;
    }
  }

  @media (min-width: 48em) {
    padding: 0 0 1.5rem 0;
    grid-auto-flow: column;
  }

  .workoutCTAs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    grid-column: 1 / -1;

    @media (min-width: 48em) {
      flex-direction: row;
      justify-content: flex-end;
      grid-column: auto;
    }
  }

  .titleBox {
    grid-column: 1;
  }
}

.singleDayWorkout {
  min-height: 34.1875rem;
  border: 1px solid var(--color-neutral-500);
  border-radius: 0.375rem;
  margin-bottom: 4rem;
  margin-left: 1.5rem;
  animation: entryAnimation 0.5s ease-in backwards;
  background: var(--color-neutral-800);

  @media (min-width: 48em) {
    margin-left: 0;
  }

  .singleDayWorkoutHeader {
    display: flex;
    justify-content: center;
    padding: 0.62rem 0rem;
    background: var(--color-neutral-500);
    text-align: center;

    & h2 {
      padding: 0;
      margin: 0;
      color: var(--color-neutral-100);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem; /* 142.857% */
      text-align: center;
    }
  }
}

.staticWorkout,
.form {
  overflow: auto;
  padding-bottom: 1.5rem;
  padding-inline: 1.5rem;

  @media (min-width: 48em) {
    padding-inline: 2.5rem;
  }
}

.richText {
  min-height: 6rem;
  flex: 1;
}

.notesContainer {
  min-height: 6rem;
}

.notes {
  min-height: 6rem;
  padding: 1rem;
  padding-left: 0;
  margin: 0;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  transition: all 0.3s ease-in-out;
}

.steps {
  display: grid;
  padding: 0;
  margin: 0;
  margin-bottom: 2.75rem;
  grid-template-columns: repeat(3, 1fr);
  list-style-type: none;

  & > * {
    animation: entryAnimation 0.3s ease-in backwards;

    &:nth-child(1) {
      animation-delay: 0.15s;
    }

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.45s;
    }
  }

  .step {
    position: relative;
    display: flex;
    min-width: 20rem;
    min-height: 30rem;
    flex-direction: column;
    padding-top: 1.5rem;
    border: 1px solid var(--color-neutral-500);
    padding-inline: 1rem;

    &.prepareStep,
    &.recoveryStep {
      padding-inline: 1.5rem;
    }
  }
}

.label {
  display: block;
  color: var(--color-neutral-100);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

.buttonGroup {
  display: flex;
  padding-left: 2.25rem;
  gap: 0.75rem;

  .button {
    min-height: 1.6875rem;
  }
}

.prepareStepIcon,
.mainStepIcon,
.recoveryStepIcon {
  position: absolute;
  top: -1rem;
  left: 1rem;
}

.workoutPartsInputListContainer {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 0.75rem 1rem;
}
