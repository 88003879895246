.header {
  display: grid;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  gap: 1.5rem;
  grid-template-columns: 1fr;

  @media (min-width: 48em) {
    grid-auto-flow: column;
    grid-template-columns: 45% auto;
  }

  .menu {
    display: grid;
    gap: 1.5rem;

    @media (min-width: 48em) {
      grid-auto-flow: column;
    }
  }
}

.eventsSectionTitle {
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.875rem;
}

.calendarGrid {
  margin-bottom: 2.75rem;
}
