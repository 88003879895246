@keyframes write {
  0% {
    transform: rotate(0) translateX(0%);
  }
  25% {
    transform: rotate(-22.5deg) translateX(20%);
  }
  50% {
    transform: rotate(0) translateX(20%);
  }
  60% {
    transform: rotate(0) translateX(60%);
  }
  75% {
    transform: rotate(0) translateX(20%);
  }
  80% {
    transform: rotate(0) translateX(60%);
  }
  90% {
    transform: rotate(0) translateX(20%);
  }
  100% {
    transform: rotate(0) translateX(0%);
  }
}

.iconContainer {
  &:hover {
    .pencil {
      animation: write 1s linear;
    }
  }
}

.icon {
  overflow: visible;
  box-sizing: content-box;
  border-bottom: currentColor solid 2.5px;
  transition: all 0.3s ease-in-out;

  .pencil {
    overflow: visible;
    transition: all 0.3s ease-in-out;
  }
}
