.combobox {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 3.25rem;
  align-items: center;
  border: 0.0625rem solid var(--color-neutral-500);
  border-radius: 0.375rem;
  color: var(--color-neutral-100);
  transition: all 0.2s ease-in-out;

  & select {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0.875rem 0.75rem;
    border: none;
    appearance: none;
    background: none;
    color: var(--color-neutral-100);
    font-family: var(--font-secondary);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.125rem;
    offset: 0;
    outline: none;
  }

  &:hover,
  &:focus-within {
    color: var(--color-neutral-100);
  }
}

/* Headless UI */
.comboboxInputContainer {
  display: grid;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: var(--color-neutral-100);
  font-weight: 500;
  grid-template-columns: 1fr auto;

  & > * {
    grid-row: 1;
  }

  .comboboxButton {
    position: relative;
    right: 0.5rem;
    padding: 0;
    border: none;
    background: none;
    color: var(--color-neutral-100);
    cursor: pointer;
    grid-column: 2;

    /* aria-disabled */
    &[aria-disabled='true'] {
      color: var(--color-neutral-400);
      cursor: not-allowed;
    }
  }

  .comboboxInput {
    overflow: hidden;
    padding: 0.875rem 0 0.875rem 1rem;
    border: none;
    margin-right: 1.75rem;
    background: none;
    color: currentColor;
    font-size: 1rem;
    grid-column: 1 / -1;
    text-overflow: ellipsis;
    white-space: nowrap;
    &[aria-disabled='true'] {
      color: var(--color-neutral-400);
      cursor: not-allowed;
    }
  }
}

.comboboxOptions {
  position: absolute;
  z-index: var(--z-index-dropdown);
  top: 3.5rem;
  display: flex;
  width: 100%;
  max-height: 14rem;
  flex-direction: column;
  padding: 0.5rem;
  border: 1px solid var(--lights-grays-5, var(--color-neutral-400));
  border-radius: 0.375rem;
  background: var(--color-neutral-700);
  box-shadow: 0px 8px 20px 0px rgba(27, 31, 40, 0.5);
  gap: 0.5rem;
  list-style-type: none;
  opacity: 0;
  overflow-y: auto;
  transition: opacity 0.3s ease-in-out;

  &[data-headlessui-state='open'] {
    opacity: 1;
    pointer-events: all;

    .item {
      &:hover,
      &:focus {
        /* TODO: Move the colors in the design system to hls() so we adjust the alpha channel */
        background: #22263195;
      }
    }
  }

  &:focus {
    outline: none;
  }
}

.comboboxOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 0.25rem;
  color: var(--color-neutral-200);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  .checkbox {
    pointer-events: none;
  }

  &[data-headlessui-state='active'],
  .active {
    /* TODO: convert colors to hsla to allow for controlling opacity */
    background: #222631a6;
  }

  &[data-headlessui-state='selected'],
  &[aria-selected='true'] {
    background: var(--color-neutral-800);
  }

  .wrapText {
    margin-right: 0.5rem;
  }
}
/*  */

.comboboxOptionWrapper {
  width: 100%;
}
