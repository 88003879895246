@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes scaleOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

@keyframes transformUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes transformDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modalContent {
  --modal-content-background: var(--color-neutral-700);

  position: fixed;
  z-index: 800;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  width: min(100vw, 30rem);
  max-height: 90dvh;
  padding: 2rem 2rem 20vh;
  border-radius: 0.625rem;
  margin: auto;
  animation: transformUp 0.3s ease-out;
  animation: transformUp 0.3s ease-out;
  background: var(--modal-content-background);
  container-type: inline-size;
  isolation: isolate;
  opacity: 0;
  transition: all 0.3s ease-out;

  @media (min-width: 48em) {
    animation: scaleIn 0.3s ease-out;
  }

  @media (min-width: 48em) {
    height: min-content;
    min-height: auto;
    max-height: 95vh;
    padding: 2rem 2.5rem;
    margin: auto;
    animation: scaleIn 0.3s ease-out;
    inset: 0;

    &[data-will-close='true'] {
      animation: scaleOut 0.3s ease-out;
    }
  }

  & form > p[id] {
    margin: 0;
    color: var(--color-neutral-200);
    text-align: center;
  }

  &.overflowVisible {
    overflow: visible;
  }
}

.modalPanel {
  width: 100%;
}

.modalOverlay {
  position: fixed;
  z-index: var(--z-index-modal-overlay);
  background: rgba(0, 0, 0, 0.5);
  inset: 0;
}

.modalTitle {
  font-family: var(--font-primary);
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.75rem;
  text-align: center;
}

.modalButtonGroup {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  gap: 1.5rem;
}

.modalError {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;

  & svg {
    width: 5rem;
    height: 5rem;
  }
}

.modalDescription {
  text-align: center;
}

.dialog {
  display: contents;

  .modalContent {
    opacity: 0;
    pointer-events: none;
    transform: translateY(100%);

    @media (min-width: 48em) {
      transform: scale(0);
    }
  }

  .modalOverlay {
    animation: fadeIn 0.3s ease-out;
    opacity: 0;
    pointer-events: none;
  }

  &[open] {
    .modalContent {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);

      @media (min-width: 48em) {
        transform: scale(1);
      }
    }

    .modalOverlay {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
