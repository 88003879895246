.button {
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0.25rem;
  background: none;
  column-gap: 0.5rem;
  font-family: var(--font-primary);
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  transition: all 0.15s ease-in-out;

  &.flex {
    display: flex;
  }

  &.inlineFlex {
    display: inline-flex;
  }

  &.relative {
    position: relative;
  }

  &.absolute {
    position: absolute;
  }

  .text {
    display: contents;
  }

  .bgInherit {
    background: inherit;
  }

  &[aria-busy='true'] {
    .text {
      display: inherit;
      opacity: 0;
    }

    .loadingSpinner {
      opacity: 1;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.small {
  padding: 0.75rem 1rem;
  column-gap: 0.25rem;
  font-size: 0.75rem;
  gap: 0.625rem;
  line-height: 1.5;
}

.medium {
  padding: 0.75rem 1rem;
  column-gap: 0.25rem;
  font-size: 0.875rem;
}

.large {
  padding: 1rem 1.5rem;
  column-gap: 0.5rem;
}

.primary.filled {
  border: none;
  background: var(--color-primary-base);
  color: var(--color-neutral-0);

  &:hover {
    background: var(--color-primary-dark);
  }

  &:active {
    background: var(--color-primary-light);
  }
}

.secondary.filled {
  border: none;
  background: var(--color-secondary-base);
  color: var(--color-neutral-800);

  &:hover {
    background: var(--color-secondary-dark);
  }

  &:active {
    background: var(--color-secondary-light);
  }
}

.primary.outlined {
  border: 1px solid currentColor;
  background: none;
  color: var(--color-primary-base);

  &:hover {
    color: var(--color-primary-dark);
  }

  &:active {
    color: var(--color-primary-light);
  }

  &:disabled {
    border: 1px solid currentColor;
    background: none;
    color: var(--color-neutral-400);
  }
}

.secondary.outlined {
  border: 1px solid currentColor;
  background: none;
  color: var(--color-secondary-base);

  &:active {
    border: 1px solid currentColor;
    color: var(--color-secondary-dark);
  }
}

.disabled.filled {
  border: none;
  background: var(--color-neutral-700);
  color: var(--color-neutral-400);

  &:active {
    border: none;
    background: var(--color-neutral-700);
    color: var(--color-neutral-400);
  }
}

.disabled.outlined {
  border: 1px solid currentColor;
  background: none;
  color: var(--color-neutral-400);

  &:active {
    border: 1px solid currentColor;
    background: none;
    color: var(--color-neutral-400);
  }
}

.ghost {
  padding: 0;
}

.loadingSpinner {
  position: absolute;
  margin: auto;
  font-size: 1.25em;
  opacity: 0;
  transition: inherit;
}

.link {
  &.primary {
    color: var(--color-primary-base);

    &:hover {
      color: var(--color-primary-light);
    }

    &:active {
      color: var(--color-primary-dark);
    }
  }
}
