.display {
  font-family: var(--font-primary);
}
.text {
  font-family: var(--font-secondary);
}

.xxl {
  font-size: 4.5rem;
  line-height: 5.65rem;
}
.xl {
  font-size: 3.75rem;
  line-height: 4.625rem;
}
.lg {
  font-size: 3rem;
  line-height: 3.75rem;
}
.md {
  font-size: 2.25rem;
  line-height: 2.75rem;
}
.sm {
  font-size: 1.25rem;
  line-height: 1.375rem;
}
.xs {
  font-size: 1rem;
  line-height: 1.25rem;
}

.text.xl {
  font-size: 1.25rem;
  line-height: 1.875rem;
}
.text.lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text.md {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text.sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text.xs {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.semibold {
  font-weight: 600;
}
